@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

.text-grotesk {
  font-family: "Space Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-grotesk-b {
  font-family: "Space Grotesk bold", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-mono {
  font-family: "Space Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-hanson {
  font-family: "Hanson";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
